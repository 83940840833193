import { useQuery } from "@tanstack/react-query";
import { fetchMarketsList } from "../api";
import { buttonClass } from "./tailwindClassNames";

export const MarketList = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { status, error: _2, data } = useQuery({
        queryKey: ["allMarkets"], // FIXME
        queryFn: () => {
            return fetchMarketsList(); // FIXME
        }
    });

    let renderedData = data?.map((market) => {
        return <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{market.name}</h5>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">{market.description}</p>
            <a href={`/${market.id}`} className={buttonClass}>
                Go to market
            </a>
        </div>
    });

    return <>
        <h1 className="text-3xl">Market list</h1>
        {renderedData}
    </>

}