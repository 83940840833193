import { useContext, useEffect } from "react";
import { MarketContext, SpanContext, ThemeContext } from "../App";
import { Chart, ms_per_period, sliceTime } from "./Chart";
import { Trade } from "./Trade";
import { Card } from "./Card";
import { dollarsPerYes, fetchLiquidityHistory, fetchMarketInfo, fetchMarketsList } from "../api";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { MarketInfoShort } from "../model";

type OverviewProps = {
  marketName: string,
  price: number,
  changePercent: number,
  currency: string
}

const Overview = ({ marketName, price, changePercent, currency }: OverviewProps) => {
  return (
    <Card>
      <span className="absolute left-4 top-4 text-primary-400 text-lg xl:text-xl 2xl:text-2xl">
        Market: {marketName}
      </span>
      <div className="w-full h-full flex items-center justify-around">
        <span className="text-2xl xl:text-4xl 2xl:text-5xl flex items-center">
          {price.toFixed(2)} Ð
          <span className="text-lg xl:text-xl 2xl:text-2xl text-neutral-400 m-2">
            {currency}
          </span>
        </span>
        <span
          className={`text-lg xl:text-xl 2xl:text-2xl ${changePercent > 0 ? "text-lime-500" : "text-red-500"
            }`}
        >
          <span>({Math.round(changePercent * 100)}%)</span>
        </span>
      </div>
    </Card>
  );
};

export const MarketDashboard = () => {
  const { darkMode } = useContext(ThemeContext);
  const { market, setMarket } = useContext(MarketContext)
  const { span } = useContext(SpanContext);
  const { id } = useParams();

  const marketId = parseInt(id!); // Should never fail
  if (market !== marketId) {
    console.log("setmarket");
    // TODO just rely on url, and get rid of MarketContext?  Might
    // be useful to know what the previous market was?
    //
    // FIXME calling setState inside render not allowed. useEffect or
    // solve some other way.  Also see <https://react.dev/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes>
    setMarket(marketId);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    status: marketsListStatus,
    error: _1,
    data: marketsListData
  } = useQuery({
    queryKey: ["marketsList"],
    queryFn: fetchMarketsList,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { status, error: _2, data } = useQuery({
    queryKey: ["liquidityHistory", market ?? 1], // FIXME
    queryFn: () => {
      return fetchLiquidityHistory(market ?? 1); // FIXME
    }
  })

  const {status: minfoStatus, error: _, data: marketinfo} = useQuery({
    queryKey: ["marketInfo", market],
    queryFn: () => {
      return fetchMarketInfo(market ?? 1); // FIXME
    }
  })


  const findMarket = (marketId: number): MarketInfoShort | undefined => {
    return marketsListData?.find(({ id }) => id === marketId)
  }

  if (!findMarket(marketId)) {
    return <div>market does not exists</div>
  }

  let price = 0;
  let movingAvgDiff = 0;
  const timeSlice = data ? sliceTime(data, Date.now() - ms_per_period[span]/5) : null
  if (timeSlice && timeSlice.length > 0) {
    const {yes_liquidity, no_liquidity} = timeSlice[timeSlice.length-1]
    price = dollarsPerYes(yes_liquidity, no_liquidity)
    const movingAvg = timeSlice
      .map(({ yes_liquidity, no_liquidity }) => dollarsPerYes(yes_liquidity, no_liquidity))
      .reduce((x, y) => x + y, 0) / timeSlice.length
    movingAvgDiff = price / movingAvg - 1
  }

  return (
    <div
      className={`h-screen grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 grid-rows-8 md:grid-rows-7 xl:grid-rows-5 auto-rows-fr gap-6 p-10 font-quicksand ${darkMode ? "bg-gray-900 text-gray-300" : "bg-neutral-100"
        }`}
    >
      <div className="md:col-span-2 row-span-4">
        <Chart />
      </div>
      <div>
        <Overview
          marketName={marketinfo?.name ?? "loading.."}
          price={price}
          changePercent={movingAvgDiff}
          currency={""}
        />
      </div>
      <div className="row-span-2 xl:row-span-3">
        <Trade />
      </div>
    </div>
  );
};
